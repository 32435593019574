<template>
  <div>
    <div>
      <h2 style="padding-bottom: 10px; display: inline-block; font-weight: 600">
        Audits
      </h2>

      <b-button
        v-b-modal.modal-new-audit
        @click="loadCompanies"
        style="float: right; margin-top: 0px; margin-right: 0 !important"
        variant="outline-dark"
        size="md"
        class="mr-1"
      >
        New audit
      </b-button>

      <b-button
        style="float: right; margin-top: 0px"
        variant="outline-dark"
        class="mr-2"
        @click="loadData"
        ><b-icon-arrow-clockwise></b-icon-arrow-clockwise
      ></b-button>
      <b-modal
        id="modal-new-audit"
        title="New audit"
        ok-title="Save"
        ok-variant="dark"
        cancel-variant="outline-dark"
        @ok="addAudit()"
      >
        <b-form @submit.stop.prevent>
          <b-form-group label="Audit name">
            <b-form-input v-model="newAudit.name" placeholder="Audit name">
            </b-form-input>
          </b-form-group>
          <b-form-group label="Client company">
            <b-form-select
              v-model="newAudit.clientCompany"
              :options="companies"
            ></b-form-select>
          </b-form-group>
          <b-form-group label="Auditee company">
            <b-form-select
              v-model="newAudit.auditeeCompany"
              :options="companies"
            ></b-form-select>
          </b-form-group>
          <b-form-group label="Status" v-slot="{ ariaDescribedby }">
            <b-form-radio
              v-model="newAudit.audit_closed"
              :aria-describedby="ariaDescribedby"
              name="open"
              value="false"
              >Open</b-form-radio
            >
            <b-form-radio
              v-model="newAudit.audit_closed"
              :aria-describedby="ariaDescribedby"
              name="closed"
              value="true"
              >Closed</b-form-radio
            >
          </b-form-group>
          <label for="feedback-user">Expiry date</label>
          <b-form-datepicker v-model="newAudit.expiryDate"></b-form-datepicker>
        </b-form>
      </b-modal>
    </div>
    <transition name="slide-fade">
      <vue-good-table
        v-if="this.audits"
        :rows="audits"
        styleClass="vgt-table"
        :columns="columns"
      >
        <template slot="table-row" slot-scope="props">
          <span
            v-if="props.column.field == 'actions'"
            style="margin-left: auto; text-align: right !important"
          >
            <b-button
              variant="outline-dark"
              size="sm"
              v-if="props.column.field === 'actions'"
              style="margin-top: -10px; margin-bottom: -10px"
              :to="'/audits/' + props.row.id"
            >
              <b-icon-arrow-right></b-icon-arrow-right>
            </b-button>
          </span>
          <span v-else-if="props.column.field === 'findings'">
            {{ props.row.closedFindings.aggregate.count }} /
            {{ props.row.totalFindings.aggregate.count }}
          </span>
          <span v-else-if="props.column.field === 'status'">
            <!-- <b-icon
              :icon="auditStatusIcon(props.row.audit_closed).icon"
              :variant="auditStatusIcon(props.row.audit_closed).variant"
              class="mr-1"
            ></b-icon> -->
            <div
              :class="
                [props.row.audit_closed ? 'tag-green' : 'tag-red'] +
                ' status-tag'
              "
            >
              {{ props.row.audit_closed ? "Closed" : "Open" }}
            </div>
          </span>
          <span v-else-if="props.column.field === 'audit_expiry_date'">
            {{ props.row.audit_expiry_date | formatDate }}
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </transition>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Audits",
  components: {},
  data() {
    return {
      fill: { gradient: ["red", "green", "blue"] },
      audits: null,
      newAudit: {
        name: null,
        audit_closed: "false",
        auditeeCompany: "00000000-0000-0000-0000-000000000000",
        clientCompany: "00000000-0000-0000-0000-000000000000",
        expiryDate: undefined,
      },
      companies: [],
      columns: [
        {
          field: "audit_name",
          label: "Audit",
        },
        {
          field: "findings",
          label: "Closed / total findings",
        },
        {
          field: "status",
          label: "Status",
          width: "80px",
          sortable: false,
        },
        {
          field: "audit_expiry_date",
          label: "Expiry date",
          type: "date",
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "dd/MM/yyyy",
        },
        {
          field: "actions",
          label: "",
          sortable: false,
          type: "number",
          width: "105px",
        },
      ],
    };
  },
  filters: {
    formatDate: function (date) {
      if (date != undefined) {
        const day = date.substring(8);
        const month = date.substring(5, 7);
        const year = date.substring(0, 4);
        return `${day}/${month}/${year}`;
      }
    },
  },
  methods: {
    closedFindings(item) {
      return `${item.closedFindings.aggregate.count} / ${item.totalFindings.aggregate.count}`;
    },
    showReportButton(item) {
      return item.audit_report === null ? true : false;
    },
    auditStatus(status) {
      return status.audit_closed === false ? "Open" : "Closed";
    },
    auditStatusIcon(status) {
      return status === false
        ? {
            icon: "exclamation-circle-fill",
            variant: "danger",
          }
        : {
            icon: "check-circle-fill",
            variant: "success",
          };
    },
    sendNotification(color, title, text, duration) {
      this.$vs.notification({
        color: color,
        duration: duration ? duration : 8000,
        title: title,
        text: text,
      });
    },
    formatDate(date) {
      if (date != undefined) {
        const day = date.substring(8);
        const month = date.substring(5, 7);
        const year = date.substring(0, 4);
        return `${day}/${month}/${year}`;
      }
    },
    async loadCompanies() {
      const token = await this.$auth.getTokenSilently();
      axios
        .request({
          method: "post",
          url: "https://graphql.api.skyswarm.sky360.com.au/v1/graphql",
          data: {
            query: `query getCompanies {
              companies {
                company_name
                id
              }
            }`,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data.errors) {
            this.sendNotification(
              "danger",
              "Uh oh! Something bad has happened...",
              `An error occured loading companies".
              <br><br>
              ${response.data.errors[0].message}`
            );
            return;
          } else {
            const rawCompanies = response.data.data.companies;

            this.companies = [];

            const dropdownCompanies = rawCompanies.map(function (row) {
              return { value: row.id, text: row.company_name };
            });
            this.companies = this.companies.concat(dropdownCompanies);
          }
        });
    },
    async report(item) {
      const token = await this.$auth.getTokenSilently();
      axios
        .request({
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            key: item.audit_report.s3_key,
          },
          url: "https://api.skyswarm.sky360.com.au/v1/audit-report/download",
        })
        .then((response) => {
          window.location.href = response.data.downloadURL;
          if (response.status === 200) {
            this.$vs.notification({
              color: "success",
              duration: "5000",
              text: "Downloading audit report...",
            });
          } else {
            this.$vs.notification({
              color: "danger",
              progress: "auto",
              duration: "8000",
              title: "Uh oh! Something bad has happened...",
              text: "An error occured while downloading the audit report",
            });
          }
        });
    },
    async addAudit() {
      const token = await this.$auth.getTokenSilently();
      const newAudit = this.newAudit;
      function checkDate() {
        if (newAudit.expiryDate != undefined) {
          return `, audit_expiry_date: "${newAudit.expiryDate}" `;
        } else {
          return "";
        }
      }
      axios
        .request({
          method: "post",
          url: "https://graphql.api.skyswarm.sky360.com.au/v1/graphql",
          data: {
            query: `mutation addAudit {
  insert_audits_one(object: {audit_name: "${newAudit.name.trim()}"${checkDate()}, audit_client_company_id: "${
              newAudit.clientCompany
            }", audit_closed: ${
              newAudit.audit_closed
            }, audit_auditee_company_id: "${newAudit.clientCompany}"}) {
    audit_name
  }
}`,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data.errors) {
            this.sendNotification(
              "danger",
              "Uh oh! Something bad has happened...",
              `An error occured while adding the audit "${newAudit.name}".
              <br><br>
              ${response.data.errors[0].message}`
            );
          }
          if (
            response.data.data.insert_audits_one.audit_name === newAudit.name
          ) {
            this.sendNotification(
              "success",
              "Audit added!",
              `"${response.data.data.insert_audits_one.audit_name}" created successfully.`
            );
            this.loadData();
          } else {
            this.sendNotification(
              "danger",
              "Uh oh! Something bad has happened...",
              `An error occured while creating the audit "${newAudit.name}".`
            );
            this.loadData();
          }
          this.newAudit.name = null;
          this.newAudit.expiryDate = undefined;
          this.newAudit.audit_closed = "false";
          (this.newAudit.auditeeCompany =
            "00000000-0000-0000-0000-000000000000"),
            (this.newAudit.clientCompany =
              "00000000-0000-0000-0000-000000000000");
        });
    },
    async loadData() {
      const token = await this.$auth.getTokenSilently();
      axios
        .request({
          method: "post",
          url: "https://graphql.api.skyswarm.sky360.com.au/v1/graphql",
          data: {
            query: `query AuditsTable {
  audits {
    audit_closed
    audit_name
    id
    audit_expiry_date
    totalFindings: audit_findings_aggregate {
      aggregate {
        count
      }
    }
    closedFindings: audit_findings_aggregate(where: {_and: {finding_closed: {_eq: true}}}) {
      aggregate {
        count
      }
    }
    audit_report {
      audit_id
      id
      s3_key
    }
  }
}`,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => (this.audits = response.data.data.audits));
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style>
.redicon {
  color: red;
}
.greenicon {
  color: limegreen;
}
.table {
  background-color: #ffffff !important;
}
.actions-column {
  width: 100px;
  min-width: 100px;
  text-align: right;
}
</style>
